@import "../../styles/typography.scss";

.projects {

    grid-template-columns: 1fr 1fr;
    display: grid;
    row-gap: 24px;
    column-gap: 50px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }

    .card {
        text-align: center;
        box-shadow: none;

        img {
            height: 30.5vw;
            max-height: 440px;

            @media (max-width: 600px) {
                height: 61vw;
            }
        }

        .title.MuiTypography-root {
            @include paragraph;
        }

        .subtitle.MuiTypography-root {
            @include paragraph-light;
        }
    }
}