@mixin heading() {
    color: #16215c;
    font-weight: 400;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: unset;
}

@mixin heading-small {
    color: #1a1b1f;
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
}

@mixin heading-medium {
    color: #16215c;
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
}

@mixin heading-large {
    color: #16215c;
    font-size: 56px;
    line-height: 80px;
    font-weight: 400;
    letter-spacing: unset;
}

@mixin paragraph {
    color: #1a1b1f;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: unset;
}

@mixin paragraph-light() {
    @include paragraph();
    opacity: 0.6;
}

@mixin paragraph-tiny() {
    color: #1a1b1f;
    margin-top: 5px;
    opacity: 0.6;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 400;
    letter-spacing: unset;
}

@mixin footer() {
    color: #1a1b1f;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    opacity: 0.5;
    letter-spacing: unset;
}

@mixin button() {
    color: #16215c;
    font-size: 12px;
    line-height: 26px;
}

@mixin navigation {
    color: #222222;
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin navigation-active {
    @include navigation();
    opacity: 1;
    color: #16215c;
    font-weight: 600;
}

@mixin navigation-hover {
    @include navigation();
    color: #32343a;
    opacity: 0.9;
}
