@import "../../../styles/typography.scss";

.section {

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 50px;
    margin-top: 24px;

    a:link {
        color: #16215c;
    }

    .button {

        .MuiTypography-root {
            @include paragraph;
            text-transform: none;
        }

        &.MuiButton-root {

            @media (max-width: 600px) {
                justify-content: center;
                width: 100%;
            }

            &:hover {
                background-color: unset;
            }
        }
    }

    iframe {
        width: 50vw;
        height: calc(50vw/1.77);
        display: block;
        margin: 0 auto;

        @media (max-width: 600px) {
            width: auto;
            height: auto;
        }
    }

    img {
        padding-top: 24px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 60%;

        @media (max-width: 600px) {
            max-width: 100%;
        }
    }

    .caption.MuiTypography-root {
        @include paragraph-tiny;
        padding-top: 10px;
        padding-bottom: 24px;
    }

    .multi-img {
        display: flex;
        flex-wrap: wrap;
    }

    .oversized-img {
        max-width: 80%;

        @media (max-width: 600px) {
            max-width: 100%;
        }
    }

    .video {
        margin-top: 24px;
    }

    .project-title {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;

        &.MuiTypography-root {
            @include heading-large;

            @media (max-width: 600px) {
                @include heading;
            }
        }
    }
    
    .project-subtitle {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;
    
        &.MuiTypography-root {
            @include paragraph;
        }
    }

    .heading.MuiTypography-root {
        @include heading-medium;
        text-align: center;
        margin-top: 24px;
    }

    .content {
        li {
            padding-left: 24px;
        }

        &.MuiTypography-root {
            @include paragraph;
            margin-top: 5px;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
        }

        &__heading {
            &.MuiTypography-root {
                @include heading-small;
                margin-top: 12px;
            }
        }
    }

    .center {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .center-text {
        text-align: center;
    }
}

