@import "../../../../styles/typography.scss";

.button-group {

    &.MuiButtonGroup-root {
        background: white;
        margin-top: 5px;
    }

    .MuiButton-root {
        @include button;
        border-color: #16215c;
        width: 160px;
    }

    .MuiButton-root:hover {
        @include button;
        border-color: #16215c;
    }

    .MuiButton-root {
        &.active {
            @include button;
            border-color: #16215c;
            background-color: #16215c;
            color: #f0eaeb;
        }
    }
}

.dropdown {
    width: 180px;
    margin-left: auto;
    margin-right: auto;

    .MuiSelect-select {
        @include button;
        text-transform: uppercase;
        text-align: center;
        width: unset;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #16215c !important;
    }
}

.dropdown-item.MuiMenuItem-root {
    @include button;
    text-transform: uppercase;
    text-align: center;
}
