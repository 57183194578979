@import "../../styles/typography.scss";

.not-found {

    text-align: center;
    
    .image {
        max-width: 30%;
        max-height: 400px;
    }
}
