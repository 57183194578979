@import "./styles/typography.scss";

#canvas {
  max-width: 1200px;
    height: auto;
    padding-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
}

html {
    overflow-y: scroll;
    height: 100%;
}

body {
    margin-left: 24px;
    margin-right: 24px;
    height: 100%;
}

#root {
    height: inherit;

    div[data-amplify-theme="default-theme"] {
        height: inherit;
    }
}

.container {
    max-width: 1200px;

    &.MuiContainer-root {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__projects {
        flex: 1 1 auto;
    }
}
