@import "../../styles/typography.scss";

.about {

    background-color: #f0eaeb;

    &__grid {
        padding: 30px;
        display: grid;
        grid-column-gap: 30px;
        grid-template-columns: 1fr 2fr;
        align-items: center;

        @media (max-width: 800px) {
            padding: 24px;
            grid-template-columns: 1fr;
            grid-column-gap: 0;
        }

        img {
            max-width: 100%;

            @media (max-width: 800px) {
                grid-row-start: 2;
                max-width: 370px;
                margin-right: auto;
                margin-left: auto;
            }

            @media (max-width: 600px) {
                max-width: 100%;
            }
        }
    
        .title.MuiTypography-root {
            @include heading;
            margin-top: 10px;
            margin-bottom: 12px;

            @media (max-width: 800px) {
                margin-top: 0;
                text-align: center;
            }
        }

        .paragraph {
            margin-bottom: 20px;
        }
        
        .paragraph.MuiTypography-root {
            @include paragraph-light;
        }

        &__buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            @media (max-width: 800px) {
                justify-content: center;
                grid-row-start: 2;
                margin-bottom: 12px;
            }

            .MuiButton-root {
                @include button;
                border-color: #16215c;
            }

            .MuiButton-root:hover {
                @include button;
                border-color: #16215c;
                background-color: #16215c;
                color: #f0eaeb;
            }
        }
    }
}