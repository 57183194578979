@import "../../styles/typography.scss";

.nav-bar {

    position: sticky;
    top: 0px;
    z-index: 1000;
    display: flex;

    &__container {
        background-color: #fff !important;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-right: unset !important;
        box-shadow: unset !important;
        position: unset !important;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 600px) {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &__content {
            justify-content: space-between;
            padding-left: unset !important;
            padding-right: unset !important;

            @media (max-width: 600px) {
                justify-content: center;
            }

            img {
                width: 80px;
                height: auto;

                @media (max-width: 600px) {
                    width: 60px;
                }
            }
    
            .title {
                padding-left: 24px;
                display: flex;
                align-items: center;
                @include paragraph-light;

                @media (max-width: 600px) {
                    display: none;
                }
            }

            .nav-item {
                @include navigation;

                &.active {
                    @include navigation-active;
                }

                &:hover {
                    @include navigation-hover;
                    background-color: unset;
                }
            }

            .menu-button {
                position: absolute;
                right: 0;

                @media (min-width: 600px) {
                    display: none;
                }
            }
        }
    }
}

.drawer.MuiDrawer-root {
    top: 80px;
    z-index: 2;

    .MuiPaper-root {
        top: unset !important;
        box-shadow: unset !important;

        .MuiListItem-root {
            justify-content: center;
        }
    }

    .MuiBackdrop-root {
        top: 108px;
    }

    .nav-item {
        @include navigation;

        &.active {
            @include navigation-active;
        }

        &:hover {
            @include navigation-hover;
            background-color: unset;
        }
    }
}
